/**
 * @enum {IstFeatureFlags}
 * For more information related to all IstFeatureFlags, please refere,
 * https://github.com/Roche-DIA-RDS-CSI/ist/blob/main/service/common/feature-domain/src/main/java/com/bina/ist/common/feature/domain/IstFeatureKey.java#L140-L157
 *
 * Only use below feature flags from the session end-point when there is a case to hide/ remove the particular feature from the UI based on the product decision
 *
 * Jira: https://rds-csi.atlassian.net/browse/ISTN-5792
 */
export enum IstFeatureFlags {
  EDIT_DIAGNOSIS = 'soup-music-year', // Edit diagnosis feature flag
  CASE_REVISION = 'math-lake-song' // Amendment and Correction feature flag
}
