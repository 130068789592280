<span *ngFor="let nameElement of name; last as isLastElement" class="word-wrap">
  <span [class.bold-text]="boldFirstName" *ngIf="displayExpandedBioMarker; else regularFirst">
    <ng-container [ngSwitch]="nameElement.first">
      <ng-container *ngSwitchCase="'MSS'">Microsatellite Stable (MSS)</ng-container>
      <ng-container *ngSwitchCase="'MSI'">Microsatellite Instability (MSI) -</ng-container>
      <ng-container *ngSwitchCase="'TMB'">Tumor Mutation Burden (TMB) -</ng-container>
      <ng-container *ngSwitchCase="'HRDsig'">Homologous Recombination Deficiency Signature (HRDsig) -</ng-container>
      <ng-container *ngSwitchCase="'Genomic LOH'">Loss of Heterozygosity (LOH) -</ng-container>
    </ng-container>
  </span>
  <ng-template #regularFirst>
    <span [class.bold-text]="boldFirstName">{{nameElement.first}}</span>
  </ng-template>
  <span *ngIf="nameElement.last" [class.expand-bio]="displayExpandedBioMarker">
    <span *ngIf="nameElement.first" ngPreserveWhitespaces> </span>
    <app-variant-last-name [last]="nameElement.last" [truncateLength]="truncateLength"></app-variant-last-name>
  </span>
  <span *ngIf="showAlias && nameElement.alias" ngPreserveWhitespaces [attr.title]="nameElement.alias"> ({{nameElement.alias}})</span>
  <span *ngIf="!isLastElement">
    <span *ngIf="multipleLines" ngPreserveWhitespaces> + </span>
    <span *ngIf="!multipleLines" ngPreserveWhitespaces>, </span>
  </span>
  <br *ngIf="multipleLines"/>
</span>
