export enum UpgradeStatus {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  LATEST = 'LATEST'
}

export interface UpgradeStatusResponse {
  status: UpgradeStatus;
  clientOnVersion: string;
  latestAvailableVersion: string;
  inProgressCasesCount: number;
}
