export enum AnnotationSourceType {
  ROCHE = 'ROCHE',
  COSMIC = 'COSMIC',
  TCGA = 'TCGA',
  CLINVAR = 'CLINVAR',
  gnomAD = 'gnomAD',
  ExAC = 'ExAC',
  DBNSFP = 'DBNSFP',
  CIViC = 'CIViC',
  Mitelman = 'Mitelman',
  dbVar = 'dbVar'
}
