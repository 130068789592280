export enum TmbMsiThresholdErrorType {
  TMB = 'TMB',
  MSI = 'MSI',
  TMB_MSI = 'TMB & MSI'
}

export enum BiomarkerThresholdTypes {
  TMB = 'TMB',
  MSI = 'MSI',
  HRD = 'HRD',
  MSI_JSD = 'MSI_JSD'
}

// Content Versions that roughly correspond to nMP versions
// Used primarily to determine if an assay needs to be upgraded or not
export enum TmbMsiThresholdVersion {
  // Roche Content Version for 2.4
  V1 = 'tmb_msi_threshold_capstone_ruo_v1',
  // Roche Content Version for 2.5
  V2 = 'tmb_msi_threshold_capstone_ruo_v2',
  // Roche Content Version for 2.6
  V3 = 'tmb_msi_hrd_threshold_capstone_ruo_v3',
  // Roche Content Version for 2.7
  V3_ASSAY_SPECIFIC = 'ASSAY_SPECIFIC_OTHER_BIOMARKER_THRESHOLDS'
}
