import { AnnotationSourceType } from '../valueObjects/annotationSourceType';
import { BaseEntity } from './baseEntity';

export class PopFreqAnnotation extends BaseEntity {
  externalLinks: {
    [externalId: string]: string;
  };
  source: AnnotationSourceType.ExAC | AnnotationSourceType.gnomAD;
  overallPopFreq: number;
  overallPopAC: number;
  overallPopAN: number;
  maxPop: string;
  maxPopFreq: number;
  maxPopAC: number;
  maxPopAN: number;

  constructor(jsonData: any) {
    super(jsonData);
  }
}
