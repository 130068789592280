import { Injectable } from '@angular/core';
import {
  BrowserStoredValue,
  BrowserStorageProxyProvider,
  BrowserStorageType
} from 'app/model/entities/browser-storage';
import { storableFrom, fromStorable } from 'app/model/entities/base64Codec';

/**
 * Abstraction for various ways of storing key-value pairs in thr browser.
 * NONE OF THESE ARE SECURE.  NEVER STORE PII, PASSWORDS, OR ANYTHING CONFIDENTIAL IN THE BROWSER.
 */
export interface BrowserStorageAdapter {
  type: BrowserStorageType;

  /**
   * Retrieve the named item from storage and deserialize it to an object.
   * @param key Item name
   */
  getItemAndDecode(key: string): any;

  /**
   * Delete the specified item from storage.
   * @param key Item name
   */
  removeItem(key: string): void;

  /**
   * JSON-stringify the value and store it.
   * @param key Item name (you probably want to have this fully qualified with a namespace)
   * @param value Anything serializable
   */
  stringifyAndSetItem(key: string, value: any): void;

  /**
   * Create a proxy to perform CRUD operations against the given key and store.
   * @param key Item name (you probably want to have this fully qualified with a namespace)
   */
  withKey<T>(key: string): BrowserStoredValue<T>;
}

/**
 * Abstractions over SessionStorage and LocalStorage.
 * Both will JSON-stringify then URI-encode stored values, then reverse when retrieving.
 * If you're going to call {@link getItem} or {@link setItem}, you'll need to do this on your own!
 */
class StoreAdapter implements BrowserStorageAdapter {
  constructor(
    public readonly type: BrowserStorageType,
    protected readonly store: Storage,
    protected readonly prefix: string = ''
  ) {}

  getItemAndDecode(key: string): any {
    const item = this.store.getItem(this.prefix + key);
    return typeof item === 'string' ? fromStorable(item) : null;
  }

  removeItem(key: string): void {
    this.store.removeItem(this.prefix + key);
  }

  stringifyAndSetItem(key: string, value: any): void {
    this.store.setItem(this.prefix + key, storableFrom(value));
  }

  withKey<T>(key: string): BrowserStoredValue<T> {
    return new BrowserStorageKeyAdapterImpl<T>(this, key);
  }
}

/**
 * Make cookies look like a real storage device.
 * Basis: developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 */
class CookieStorageAdapter implements BrowserStorageAdapter {
  type = BrowserStorageType.COOKIE;

  constructor(public readonly prefix: string = '') {}

  getItemAndDecode(key: string): any {
    const encodedKey = encodeURIComponent(this.prefix + key);
    const reSafeKey = encodedKey.replace(/[-.+(*]/g, '\\$&');
    const keyAndValue = new RegExp('(?:(?:^|.*;)\\s*' + reSafeKey + '\\s*\\=\\s*([^;]*).*$)|^.*$');
    const encodedValue = document.cookie.replace(keyAndValue, '$1');
    const item = decodeURIComponent(encodedValue);

    // Try to see if value string is encoded, if not return string as is.
    try {
      return item && typeof item === 'string' ? fromStorable(item) : null;
    } catch (e) {
      return item;
    }
  }

  removeItem(key: string): void {
    const prefixedKey = this.prefix + key;
    document.cookie = encodeURIComponent(prefixedKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }

  stringifyAndSetItem(key: string, value: any): void {
    const prefixedKey = this.prefix + key;
    const encodedValue = storableFrom(value);
    document.cookie =
      encodeURIComponent(prefixedKey) +
      '=' +
      encodedValue +
      '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }

  withKey<T>(key: string): BrowserStoredValue<T> {
    return new BrowserStorageKeyAdapterImpl<T>(this, key);
  }
}

class BrowserStorageKeyAdapterImpl<T> implements BrowserStoredValue<T> {
  constructor(public readonly adapter: BrowserStorageAdapter, public readonly key: string) {}

  read = () => this.adapter.getItemAndDecode(this.key);

  remove = () => this.adapter.removeItem(this.key);

  write = (value: T) => this.adapter.stringifyAndSetItem(this.key, value);
}

@Injectable()
export class BrowserStorageService implements BrowserStorageProxyProvider {
  proxy<T>(type: BrowserStorageType, namespace: string, key: string): BrowserStoredValue<T> {
    const prefix = namespace == null || namespace === '' ? '' : namespace + '.';
    let adapter: BrowserStorageAdapter;
    switch (type) {
      case BrowserStorageType.COOKIE:
        adapter = new CookieStorageAdapter(prefix);
        break;
      case BrowserStorageType.LOCAL:
        adapter = new StoreAdapter(type, localStorage, prefix);
        break;
      case BrowserStorageType.SESSION:
        adapter = new StoreAdapter(type, sessionStorage, prefix);
        break;
      default:
        throw new Error(`Unknown BrowserStorageType: ${type}`);
    }
    return adapter.withKey(key);
  }
}
