import { AutoLogoutService } from './services/auto-logout.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from './../environments/environment';
import { ToasterService } from 'app/services/toaster.service';
import { AuthService } from 'app/services/auth.service';
import { FileUploadService } from 'app/services/file-upload.service';
import { AuthUser } from 'app/model/entities/authUser';
import { NavBarService } from './services/nav-bar.service';
import { CustomIconService } from './services/custom-icon.service';
import { CommonService } from './services/common.service';
import { BrowserStorageService } from './services/browser-storage.service';
import { BrowserStorageType } from './model/entities/browser-storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private showBrowserWarning = false;

  public currentUser$: Observable<AuthUser>;
  userWasLoggedIn = false;
  window = window;

  @HostListener('window:beforeunload', ['$event'])
  checkBrowserWarning($event) {
    if (this.showBrowserWarning) {
      // Note: browsers will display some default message instead of what is specified here
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
      $event.returnValue = 'Changes you made may not be saved.';
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const elCollection = document.getElementsByClassName('page-fixed-footer');
    const footerEl = document.getElementById('footer');
    if (elCollection.length > 0 && footerEl) {
      const fixedFooterEl = <HTMLElement>elCollection.item(elCollection.length - 1);

      // Scrollbar reaches bottom - close to the end of the fixed action footer bar
      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight + footerEl.offsetHeight + 31
      ) {
        // 62px (reference: _layout.scss) is page footer height, hence make sticky footer sit on top of page footer.
        fixedFooterEl.setAttribute('style', 'bottom: 62px;');
      } else {
        fixedFooterEl.setAttribute('style', 'bottom: 0'); // Back to it's original position
      }
    }
  }

  constructor(
    public location: Location,
    private toasterService: ToasterService,
    private fileUploadService: FileUploadService,
    private authService: AuthService,
    private router: Router,
    private autoLogoutService: AutoLogoutService,
    private navBarService: NavBarService,
    private customIconService: CustomIconService, // for registering custom icons
    private browserStorageService: BrowserStorageService
  ) {
    this.currentUser$ = this.authService.currentUser;
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      // Since, `router.events` are being listened at 'app.component' level, this will be called everytime we navigate to a route
      // It will clear the sub-title and back link and then respective child component will re-assign the value if needed.
      this.navBarService.clearSubTitle();
      this.navBarService.clearBackLink();
    });

    this.checkUpgradeCookie();
    this.checkPendingUpload();
    this.checkCurrentUser();
    this.autoLogoutService.start();
  }

  checkUpgradeCookie(): void {
    const cookieService = this.browserStorageService.proxy(
      BrowserStorageType.COOKIE,
      '',
      'ist_upgrade'
    );
    const upgradeCookie = cookieService.read();

    if (upgradeCookie) {
      cookieService.remove();
      this.window.location.href = `/${upgradeCookie}`;
    }
  }

  checkCurrentUser(): void {
    this.authService.currentUser.subscribe((currentUser) => {
      if (currentUser) {
        this.userWasLoggedIn = true;
        this.authService.startSessionPolling();

        // Code for terms, replace the redirectURL lines with commented code when enable terms
        // this.checkTermsStatus();
        const redirectURL = this.authService.popRedirectURL();
        if (redirectURL) this.router.navigateByUrl(redirectURL, { replaceUrl: true });
      } else if (this.userWasLoggedIn && !this.location.path().startsWith('/login')) {
        if (environment.platform) {
          // On logout, navigate to the athens logout
          CommonService.reloadApp('/athLogoff');
        } else {
          // On logout, navigate to the login page
          this.router.navigateByUrl('/login');
        }
      }
    });
  }

  checkPendingUpload(): void {
    this.fileUploadService.uploadPendingCases.subscribe((uploadPendingCases) => {
      Object.keys(uploadPendingCases).forEach((caseId) => {
        if (uploadPendingCases[caseId].uploadPending) {
          this.toasterService.uploadPending(caseId, uploadPendingCases[caseId]);
          this.showBrowserWarning = true;
        } else {
          this.toasterService.clearUploadPending(caseId);
          this.showBrowserWarning = false;
        }
      });
    });
  }

  // Code for terms, will be used for alpha 3, do not remove
  private checkTermsStatus(): void {
    if (this.authService.getTermsStatus()) {
      const redirectURL = this.authService.popRedirectURL();
      if (redirectURL) {
        this.router.navigateByUrl(redirectURL);
      }
      // User can access case report without accepting agreement since the case report is generated in other browsers
    } else if (!/\/case\/.+\/report$/.test(window.location.pathname)) {
      this.router.navigateByUrl('/terms');
    }
  }
}
